const COUNT_LIMIT = 999;

// Get all article elements from the DOM
const getAllArticlesFromDOM = selectorString =>
  Array.from(document.querySelectorAll(selectorString));

// Extract necessary elements from each article
const getArticleElements = article => {
  const articleId = article.dataset.id;

  if (!articleId) {
    return null;
  }

  const elements = {
    articleId,
    articleCommentsCountElement: article.querySelector('.js-article-preview-comments-count'),
    articleCommentsElement: article.querySelector('.js-article-preview-comments'),
    articleReactionsCountElement: article.querySelector('.js-article-preview-reactions-count'),
    articleReactionsElement: article.querySelector('.js-article-preview-reactions')
  };

  return Object.values(elements).every(Boolean) ? elements : null;
};

// Create a map of article elements
const createArticleEntries = articles => {
  const articlesMap = new Map();

  articles.forEach(article => {
    const articleElements = getArticleElements(article);

    if (articleElements) {
      articlesMap.set(articleElements.articleId, articleElements);
    }
  });

  return articlesMap;
};

// Update article count with a given type (reaction or comment)
const updateArticleCount = (article, count, type) => {
  const countElement = type === 'reaction' ? article.articleReactionsCountElement : article.articleCommentsCountElement;

  countElement.innerText = typeof count === 'number' && count > COUNT_LIMIT ? `${COUNT_LIMIT}+` : count.toString();
};

const setArticlesReactionsAndComment = (articleIds, articleEntries) => {
  fetch(`${window.Site.commentsDomain}articles/comments/count?articleIds=${articleIds}`)
    .then(response => response.json())
    .then(articleCommentsCount => {
      articleCommentsCount.forEach(({id, count}) => {
        requestAnimationFrame(() => {
          const articleEntry = articleEntries.get(id);

          if (articleEntry && count > 0) {
            updateArticleCount(articleEntry, count, 'comment');
            articleEntry.articleCommentsElement.classList.remove('hidden');
          }
        });
      });
    })
    .catch(error => {
      console.error('Failed to fetch articles comments on home page:', error);
    });

  fetch(`${window.Site.commentsDomain}articles/reactions/count?articleIds=${articleIds}`)
    .then(response => response.json())
    .then(articleReactionsCount => {
      articleReactionsCount.forEach(({id, count}) => {
        requestAnimationFrame(() => {
          const articleEntry = articleEntries.get(id);

          if (articleEntry && count > 0) {
            updateArticleCount(articleEntry, count, 'reaction');
            articleEntry.articleReactionsElement.classList.remove('hidden');
          }
        });
      });
    })
    .catch(error => {
      console.error('Failed to fetch articles reactions on home page:', error);
    });
};

const initArticleReactionsAndComments = (selectorString = '.js-article') => {
  const articleEntries = createArticleEntries(getAllArticlesFromDOM(selectorString));

  if (articleEntries.size === 0) {
    return;
  }

  const articleIds = Array.from(articleEntries.keys()).join(',');

  setArticlesReactionsAndComment(articleIds, articleEntries);
};

export {initArticleReactionsAndComments};
